
import s0 from '~/.nuxt/gb-cms/sections/BoardList'
import s1 from '~/.nuxt/gb-cms/sections/ContactForm'
import s2 from '~/.nuxt/gb-cms/sections/GalleryPage'
import s3 from '~/.nuxt/gb-cms/sections/GallerySection'
import s4 from '~/.nuxt/gb-cms/sections/HeroSection'
import s5 from '~/.nuxt/gb-cms/sections/PrivacyPolicy'

const sectionMapper = {
	'board-list': s0,
	'contact-form': s1,
	'gallery-page': s2,
	'gallery-section': s3,
	'hero-section': s4,
	'privacy-policy': s5
}

export default sectionMapper
