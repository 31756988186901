
export default {
  props: {
    type: {
      type: String,
      default: 'text',
    },
    error: {
      type: [Boolean],
      default: false,
    },
    value: {
      type: [String, Number],
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    textarea: {
      type: Boolean,
      default: false,
    },
    inputId: {
      type: String,
      required: true,
    },
  },
  computed: {
    inputStyle() {
      let classes

      if (this.error) {
        classes = 'border-2 border-error placeholder-error font-medium'
      } else classes = 'border border-light-gray placeholder-dark-gray'

      return classes
    },
    inputVal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
