
export default {
  data() {
    return {
      modal: false,
      arts: [],
      latestImages: [],
      currentImage: {},
      galleryPage: {},
      options: [
        {
          name: 'name',
          label: 'Ime',
          type: 'text',
        },
        {
          name: 'surname',
          label: 'Prezime',
          type: 'text',
        },
        {
          name: 'info',
          label: 'Podaci o autoru',
          type: 'text',
        },
        {
          name: 'artName',
          label: 'Naziv djela',
          type: 'text',
        },
        {
          name: 'artDesc',
          label: 'O autoru',
          type: 'text',
        },
        {
          name: 'artDesc2',
          label: 'O autoru',
          type: 'text',
        },
        {
          name: 'image',
          label: 'Slika',
          type: 'image',
        },
        {
          name: 'facebook',
          label: 'Facebook',
          type: 'link',
        },
        {
          name: 'web',
          label: 'web',
          type: 'link',
        },
        {
          name: 'instagram',
          label: 'Instagram',
          type: 'link',
        },
      ],
    }
  },
  mounted() {
    this.getPageData()
  },
  methods: {
    async getPageData() {
      try {
        const data = await this.$axios.get(
          '/api/v1/cms/finder/decide?originalTranslation=true&params[]=galerija'
        )
        if (data) {
          this.galleryPage = data.data.data
          const gallery = this.galleryPage.components[0].linkMeta.gallery
          this.latestImages = gallery.splice(gallery.length - 8, gallery.length)
        }
      } catch (e) {
        console.log(e)
      }
    },
    iframeLoaded() {
      const items = JSON.parse(localStorage.getItem('latestImages'))
      this.latestImages.splice(0, this.latestImages.length, ...items)
    },
    openModal(image) {
      setTimeout(() => {
        this.modal = true
        this.currentImage = image
        document.body.style.overflowY = 'hidden'
      }, 10)
    },
    closeModal() {
      this.currentImage = {}
      this.modal = false
      document.body.style.overflowY = 'auto'
    },
  },
}
