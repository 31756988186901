
export default {
  data() {
    return {
      options: [
        {
          name: 'title',
          label: 'Naslov',
          type: 'text',
        },
      ],
      optionsPeople: [
        {
          name: 'name',
          label: 'Ime&prezime',
          type: 'text',
        },
        {
          name: 'desc',
          label: 'opis',
          type: 'text',
        },
        {
          name: 'desc2',
          label: 'opis2',
          type: 'text',
        },

        {
          name: 'image',
          label: 'Slika',
          type: 'image',
        },
        {
          name: 'facebook',
          label: 'Facebook',
          type: 'link',
        },
        {
          name: 'web',
          label: 'web',
          type: 'link',
        },
        {
          name: 'instagram',
          label: 'Instagram',
          type: 'link',
        },
      ],
    }
  },
}
