
import getVideoId from 'get-video-id'
export default {
  data() {
    return {
      isYoutube: false,
      youtubeSrc: null,
      videoID: null,
    }
  },
  head() {
    return {
      link: [
        {
          rel: 'stylesheet',
          href: 'node_modules/lite-youtube-embed/src/lite-yt-embed.css',
        },
      ],
    }
  },
  mounted() {
    if (this.$refs.youtubeLink) {
      const src = this.$refs.youtubeLink.$el.outerText.replace(
        'watch?v=',
        'embed/'
      )
      const url = this.$refs.youtubeLink.$el.outerText
      console.log(url)

      if (!src || !src.length) {
        this.isYoutube = false
      } else {
        this.youtubeSrc = src
        this.videoID = getVideoId(url)
        this.isYoutube = true
      }
    }
  },
}
