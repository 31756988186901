
export default {
  mounted() {
    var nesto = document.getElementsByTagName('li')
    const nodes = Array.from(nesto)
    nodes.forEach((node) => {
      node.style.margin = '0 0 0 25px'
    })
  },
}
