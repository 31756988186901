
export default {
  data() {
    return {
      modal: false,
      arts: [],
      items: [],
      loaded: 12,
      currentImage: {},
      options: [
        {
          name: 'name',
          label: 'Ime',
          type: 'text',
        },
        {
          name: 'surname',
          label: 'Prezime',
          type: 'text',
        },
        {
          name: 'info',
          label: 'Podaci o autoru',
          type: 'text',
        },
        {
          name: 'artName',
          label: 'Naziv djela',
          type: 'text',
        },
        {
          name: 'artDesc',
          label: 'O autoru',
          type: 'text',
        },
        {
          name: 'artDesc2',
          label: 'O autoru',
          type: 'text',
        },
        {
          name: 'image',
          label: 'Slika',
          type: 'image',
        },
        {
          name: 'facebook',
          label: 'Facebook',
          type: 'link',
        },
        {
          name: 'web',
          label: 'web',
          type: 'link',
        },
        {
          name: 'instagram',
          label: 'Instagram',
          type: 'link',
        },
      ],
    }
  },
  methods: {
    openModal(image) {
      setTimeout(() => {
        this.modal = true
        this.currentImage = image
        document.body.style.overflowY = 'hidden'
      }, 10)
    },
    closeModal() {
      this.currentImage = {}
      this.modal = false
      document.body.style.overflowY = 'auto'
    },
  },
  mounted() {
    this.items = this.$refs.editorList.items
    const copy = JSON.parse(JSON.stringify(this.items))
    const spliced = copy.splice(this.items.length - 8, this.items.length)
    localStorage.setItem('latestImages', JSON.stringify(spliced))
  },
}
